* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 200;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
}

.fill-height {
  min-height: 100vh;
  max-height: 100vh;
}

a {
  color: #fff;
}

::-moz-placeholder {
  color: #fff;
  opacity: .5;
}

::placeholder {
  color: #fff;
  opacity: .5;
}

/*# sourceMappingURL=index.810c4a29.css.map */
