* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 24px;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
}

.fill-height {
  min-height: 100vh;
  max-height: 100vh;
}

a {
  color: #fff;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
  opacity: 0.5;
}